import React from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import MenuIcon from '../MenuIcon';
const StyledMenuContainer = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
`;
const StyledMenu = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;

  height: calc(100vh - 140px);
  width: calc(100vw);

  transform: translate(0, 70px);

  opacity: ${(props) => props.active
    ? 1
    : 0};

  transition: 500ms linear 200ms;
`;
const StyledMenuOption = styled.div `
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  transform: translate(-30px, -20px);
  opacity: 0;
  font-weight: 300;
  margin: 10px 0;
  z-index: 200;

  & > a {
    text-decoration: none;
    color: white;
  }

  ${(props) => (() => {
    const animationArr = [];
    for (let i = 1; i <= 10; i++) {
        animationArr.push(`
          &:nth-of-type(${i}) {
            animation: 500ms menu-option-appear ${i * 75}ms 1 ${!!props.active ? 'forwards' : 'backwards'};
          }
        `);
    }
    return animationArr.join('\n');
})()};

  @keyframes menu-option-appear {
    0% {
      transform: translate(-20px, -30px);
      opacity: 0;
    }
    100% {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  }
`;
const Menu = () => {
    const [active, setActive] = React.useState(false);
    return (React.createElement(StyledMenuContainer, null,
        React.createElement(MenuIcon, Object.assign({}, { active, setActive })),
        !active
            ? null
            : (React.createElement(StyledMenu, Object.assign({}, { active }),
                React.createElement(StyledMenuOption, Object.assign({}, { active }, { onClick: () => setActive(false) }),
                    React.createElement(HashLink, { to: "/#home", smooth: true }, "home")),
                React.createElement(StyledMenuOption, Object.assign({}, { active }, { onClick: () => setActive(false) }),
                    React.createElement(HashLink, { to: "/#details", smooth: true }, "details")),
                React.createElement(StyledMenuOption, Object.assign({}, { active }, { onClick: () => setActive(false) }),
                    React.createElement(HashLink, { to: "/#about-us", smooth: true }, "about us")),
                React.createElement(StyledMenuOption, Object.assign({}, { active }, { onClick: () => setActive(false) }),
                    React.createElement(HashLink, { to: "/#location", smooth: true }, "location")),
                React.createElement(StyledMenuOption, Object.assign({}, { active }, { onClick: () => setActive(false) }),
                    React.createElement(HashLink, { to: "/#registries", smooth: true }, "registries")),
                React.createElement(StyledMenuOption, Object.assign({}, { active }, { onClick: () => setActive(false) }),
                    React.createElement(HashLink, { to: "/#rsvp", smooth: true }, "rsvp"))))));
};
export default Menu;
