import React from 'react';
import styled, { css } from 'styled-components';
const StyledMenuIconContainer = styled.div `
  position: fixed;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  z-index: ${(props) => !props.active
    ? 2
    : 0};
`;
const StyledMenuIconCircle = styled.div `
  position: absolute;
  background: rgba(240, 215, 144, 0.6);
  border-radius: 100%;
  z-index: -1;

  width: 40px;
  height: 40px;

  transition: 700ms ease-in-out;

  background: ${(props) => !props.active
    ? 'rgba(240, 215, 144, 0.6)'
    : 'rgba(240, 215, 144, 0.95)'};

  transform: ${(props) => !props.active
    ? 'scale(1, 1)'
    : 'scale(120, 120)'};
`;
const StyledMenuIcon = styled.svg `
  cursor: pointer;
`;
const StyledMenuIconG = styled.g `
  stroke-linecap: round;
  stroke-width: 2px;
  fill: none;
  stroke: white;
`;
const StyledMenuIconBar = styled.path `
  &:first-child {
    transform-origin: 10px 8px;
    transform: translateY(0px) rotate(0deg);
  }

  &:last-child {
    transform-origin: 10px 12px;
    transform: translateY(0px) rotate(0deg);
  }

  transition: 200ms linear;

  ${(props) => !!props.active && css `
    &:first-child {
      transform: translateY(2px) rotate(45deg);
    }

    &:last-child {
      transform: translateY(-2px) rotate(-45deg);
    }
  `};
`;
const MenuIcon = ({ active, setActive }) => {
    return (React.createElement(StyledMenuIconContainer, Object.assign({}, { active }),
        React.createElement(StyledMenuIconCircle, Object.assign({}, { active })),
        React.createElement(StyledMenuIcon, { width: 40, height: 40, viewBox: "0 0 20 20", onClick: () => setActive(!active) },
            React.createElement(StyledMenuIconG, null,
                React.createElement(StyledMenuIconBar, Object.assign({}, { active }, { d: "M5,8L15,8" })),
                React.createElement(StyledMenuIconBar, Object.assign({}, { active }, { d: "M5,12L15,12" }))))));
};
export default MenuIcon;
