import React from 'react';
import Loadable from 'react-loadable';
export default Loadable({
    loader: () => import('./RsvpPage'),
    loading: () => (React.createElement("section", { style: {
            background: 'url(/img/rsvp-background.small.jpg) no-repeat center center',
            backgroundSize: 'cover',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100vh',
            minHeight: '100vh',
            maxHeight: '100vh',
            width: '100vw',
            minWidth: '100vw',
            maxWidth: '100vw',
            overflow: 'hidden',
        } })),
});
