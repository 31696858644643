import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { hydrate } from 'react-dom';
import App from './App';
import './client.css';
hydrate(React.createElement(BrowserRouter, null,
    React.createElement(App, null)), document.getElementById('root'));
if (module.hot) {
    module.hot.accept();
}
