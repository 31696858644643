import React from 'react';
import Loadable from 'react-loadable';
export default Loadable({
    loader: () => import('./DetailsPage'),
    loading: () => (React.createElement("section", { style: {
            height: '100vh',
            width: '100vw',
            overflow: 'hidden',
        } })),
});
