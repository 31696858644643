import React from 'react';
import Loadable from 'react-loadable';
export default Loadable({
    loader: () => import('./LandingPage'),
    loading: () => (React.createElement("section", { style: {
            height: '100vh',
            width: '100vw',
            overflow: 'hidden',
            background: '#1F1F1F',
        } },
        React.createElement("div", { style: {
                background: 'url(/img/landing-page-background.small.jpg) no-repeat center center',
                backgroundSize: 'cover',
                height: '100vh',
                width: '100vh',
            } }))),
});
