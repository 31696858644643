import React from 'react';
import styled from 'styled-components';
import LandingPage from '../views/LandingPage/Loadable';
import DetailsPage from '../views/DetailsPage/Loadable';
import AboutUsPage from '../views/AboutUsPage/Loadable';
import GiftsPage from '../views/GiftsPage/Loadable';
import DirectionsPage from '../views/DirectionsPage/Loadable';
import RsvpPage from '../views/RsvpPage/Loadable';
import Menu from '../components/Menu';
const StyledApp = styled.main `
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100vw;

  overflow-x: hidden;

  font-family: 'Open Sans', sans-serif;
`;
const App = ({ children }) => (React.createElement(React.Fragment, null,
    React.createElement(StyledApp, { id: "app" },
        React.createElement(LandingPage, null),
        React.createElement(DetailsPage, null),
        React.createElement(AboutUsPage, null),
        React.createElement(DirectionsPage, null),
        React.createElement(GiftsPage, null),
        React.createElement(RsvpPage, null)),
    React.createElement("div", { style: { position: 'fixed', top: 0, left: 0, zIndex: 1000 } },
        React.createElement(Menu, null))));
export default App;
