import React from 'react';
import Loadable from 'react-loadable';
export default Loadable({
    loader: () => import('./AboutUsPage'),
    loading: () => (React.createElement("section", { style: {
            height: '100vh',
            minHeight: '100vh',
            maxHeight: '100vh',
            width: '100vw',
            minWidth: '100vw',
            maxWidth: '100vw',
            overflow: 'hidden',
        } },
        React.createElement("div", { style: {
                background: 'url(/img/about-us-page-background.small.jpg) no-repeat center center',
                backgroundSize: 'cover',
                minWidth: '100%',
                minHeight: '100vh',
                overflow: 'hidden',
            } }))),
});
